import useAcquisitionStore from "../stores/acquisition";
const router = useRouter()

export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.server) return

  const store = useAcquisitionStore()


  if (store.currentCatIndex !== 0) {
    if (store.currentCatIndex === 1) {
      router.push({ name: 'funnel-merge-menu'})
    }

    router.push({ name: 'funnel-generate'})
  }

  return
})